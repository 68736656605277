<template>
  <div>

    <div>
      <h2 class="wsDARKER mb-5"
          :class="[{'mt-12' : !SM} , {'mt-6' : SM}]"
          :style="`font-size: ${!SM ? 24 : 19}px`"
      >
        {{  $t('wsu_structure.specialty.program.title')  }}
      </h2>


      <v-row>
      <v-col v-for="(item,index) in entity.content" :key="index"
             cols="12" md="4">
        <v-sheet
            style="border: 1px solid var(--wsBACKGROUND); position: relative"
            class="pa-4 wsRoundedSemi fill-height"
        >
          <img
              v-if="item.img"
              :src="item.img"
              height="150px"
              width="100%"
              position="top"
              class="mr-5 wsRoundedSemi"
              style="object-fit: cover"
          />

        <h5 class="font-weight-regular mt-3">
          {{ item.text }}
        </h5>
        </v-sheet>
      </v-col>
    </v-row>

    </div>
  </div>

</template>

<script>
export default {
  name: "programCareer",
  props : {
    entity : {
      type : Object,
      default() { return {}}
    }
  }
}
</script>



<style scoped>

</style>